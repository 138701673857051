<template>
  <div class="line flex pt-8 w-full">
    <span class="bg-ansGreen"></span>
    <span class="bg-ansOrange"></span>
    <span class="bg-ansLemon"></span>
  </div>
</template>

<script>
export default {
  name: "Lines",
};
</script>

<style scoped>
.line > span {
  @apply w-1/3;
  /* width: 103.67px; */
  height: 4px;
}
</style>
